<template>
  <div v-loading="loaders.resource" class="p-4">
    <h1 class="py-3">Newsletter</h1>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form role="form" type="multipart" @submit.prevent="handleSubmit(updateNewsletterData)">
        <card type="default" header-classes="bg-transparent">
          <div class="row m-0 my-4">
            <div class="col-6 row m-0 mb-4">
              <label class="form-control-label w-100">PDF</label>
              <div class="col-md-6 pl-0 pr-2">
                <div class="radio-button-card pl-3">
                  <base-radio name="v1" v-model="selectedValue" value="pdfValues.r1.value" class="pt-3 base-radio-button" selected>
                    <span class="base-radio-text">Yes</span>
                  </base-radio>
                </div>
              </div>
              <div class="col-md-6 px-0">
                <div class="radio-button-card pl-3">
                  <base-radio name="v2" v-model="selectedValue" value="pdfValues.r2.value" class="pt-3 base-radio-button">
                    <span class="base-radio-text">No</span>
                  </base-radio>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6" v-if="selectedValue=='v1'">
              <label class="form-control-label" for="pdf">PDF File*</label>
              <input type="file" id="pdf" ref="pdf" accept="application/pdf" class="form-control file-upload"
                     @change="onFileChange">
              <span class="font-size-12px">File size should be 20Mb or less.</span>
            </div>
            <div class="col-lg-6 col-md-6" v-if="selectedValue=='v2'">
              <label class="form-control-label" for="url">URL*</label>
              <input id="url" type="url" class="form-control url-height" v-model="newsletter.url" required>
            </div>
            <div class="col-lg-6 col-md-6">
              <base-input name="status" label="Status*" :rules="{required: true}">
                <el-select
                  class="w-100"
                  label="Status"
                  v-model="newsletter.status">
                  <el-option
                    v-for="option in dropdowns.status"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </card>
        <base-button native-type="submit" type="submit" class="px-5 btn-primary-action font-weight-bold">
          Update
        </base-button>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "Newsletter",
  data() {
    return {
      loaders: {
        resource: false
      },
      newsletter: {
        url: '',
        is_pdf: 0,
        pdf_name: '',
        pdfFile: '',
        status: '',
      },
      pdfValues: {
        v1: {value: 1, label: 'Yes'},
        v2: {value: 0, label: 'No'},
      },
      selectedValue: 'v2',
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ]
      },
    }
  },
  created() {
    this.fetchNewsletterData()
  },
  methods: {

    /**
     * Save the pdf file into newsletter object
     */
    onFileChange(e) {
      if (e.target.files[0].size > 20000000) {
        this.$notify.error({
          title: 'Error',
          message: 'The pdf file size can not be greater than 20Mb.'
        })
        this.$refs.pdf.value = null
        return
      }
      this.newsletter.pdfFile = e.target.files[0]
    },

    /**
     * Fetch the newsletter website data
     */
    fetchNewsletterData() {
      let vm = this
      vm.loaders.resource = true
      vm.$store.dispatch('_fetchNewsletterWebsite').then(response => {
        if (response.data.data) vm.newsletter = response.data.data
        vm.selectedValue = vm.newsletter.is_pdf === 1 ? 'v1' : 'v2'
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Newsletter',
          message: message
        })
      })
      vm.loaders.resource = false
    },

    /**
     * Update the newsletter website data
     */
    async updateNewsletterData() {
      let vm = this
      vm.loaders.resource = true
      const isNewsletterFormValid = await this.$refs['formValidator'].validate();
      if (isNewsletterFormValid) {
        vm.newsletter.is_pdf = (vm.selectedValue === 'v1') ? 1 : 0
        const formData = new FormData();
        _.each(vm.newsletter, (value, key) => {
          formData.append(key, value)
        })
        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }
        vm.$store.dispatch('_updateNewsletterWebsite', {config, formData})
          .then(response => {
            vm.$notify.success({
              title: 'Newsletter',
              message: 'Newsletter Updated Successfully. Always check to see how your changes look in the Baritastic app.'
            })
            vm.newsletter = response.data.data
            vm.selectedValue = vm.newsletter.is_pdf === 1 ? 'v1' : 'v2'
          })
          .catch(error => {
            let message = 'Something went wrong please try again in few minutes.';
            if (error.response) {
              const errors = error.response.data.errors;
              const firstError = Object.keys(errors)[0];
              message = errors[firstError][0];
            }
          vm.$notify.error({
            title: 'Newsletter',
            message: message
          })
        })
        .finally(() => vm.loaders.resource = false)
      } else {
        vm.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

  }
}
</script>

<style scoped>

.url-height{
  height: 54px;
}

.radio-button-card {
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.base-radio-text {
  font: normal normal normal 14px/19px Open Sans;
  color: #525F7F;
}

.form-control.file-upload {
  height: calc(1.5em + 1.25rem + 13px);
  padding-top: calc(0.75rem + 3px);
}

</style>
